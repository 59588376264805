<template>

    <div>
        <auth-top-bar :initialData="initialData" />

        <div class="auth-wrap">
            <div class="auth-sidebar">
                <sidebar-step :initialData="initialData" :step="2"/>
            </div>
            <div class="auth-content">

                <main class="auth-main">
                    <div class="d-flex justify-content-center flex-column register-form-wrap"><br/>

                        <h1 class="page-heading pt-3 pt-xl-0 pb-4 mb-xl-2">{{ initialData.lang.app_tell_us_about_yourself }}</h1>
                        <error-ele v-if="formValidationErrors && formValidationErrors['verification_token']" :errors="formValidationErrors['verification_token']" />
                        <form class="register-form" autocomplete="off">
                            <div class="form-group">
                                <input type="text" class="form-control" :placeholder="initialData.lang.profile.fields.firstname" v-model="form.firstname" autocomplete="off" autofill="off">
                                <error-ele v-if="formValidationErrors && formValidationErrors['firstname']"
                                           :errors="formValidationErrors['firstname']" />
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" :placeholder="initialData.lang.profile.fields.lastname" v-model="form.lastname" autocomplete="off" autofill="off">
                                    <error-ele v-if="formValidationErrors && formValidationErrors['lastname']"
                                               :errors="formValidationErrors['lastname']" />
                                </div>
                                <div class="form-group col-md-6">
                                    <v-datepicker
                                        name="dob"
                                        :placeholder="initialData.lang.profile.fields.dob"
                                        v-model="form.dob"
                                        format="YYYY-MM-DD"
                                        class="dob"
                                        :locale="initialData.current_lang"
                                        :showDropDowns="true"
                                        :error="(formValidationErrors && formValidationErrors['dob']) ? formValidationErrors['dob'][0] : null" />
                                </div>

                            </div>

                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" :placeholder="initialData.lang.profile.fields.address" v-model="form.address" autocomplete="off" autofill="off">
                                        <error-ele v-if="formValidationErrors && formValidationErrors['address']"
                                           :errors="formValidationErrors['address']" />
                                </div>

                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" readonly="readonly" :placeholder="initialData.lang.profile.fields.city" v-model="form.city" autocomplete="off" autofill="off">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" :readonly="form.province ? 'readonly' : ''" :placeholder="initialData.lang.profile.fields.province" v-model="form.province" autocomplete="off" autofill="off">
                                        <error-ele v-if="formValidationErrors && formValidationErrors['province']"
                                           :errors="formValidationErrors['province']" />
                                </div>
                                <div class="form-group col-md-6">
                                    <input type="text" class="form-control" :placeholder="initialData.lang.profile.fields.postalcode" v-model="form.postalcode" autocomplete="off" autofill="off">
                                        <error-ele v-if="formValidationErrors && formValidationErrors['postalcode']"
                                           :errors="formValidationErrors['postalcode']" />
                                </div>
                            </div>

                            <div class="form-group">
                                <input type="text" @keypress="isNumberKey" required class="form-control" :placeholder="initialData.lang.profile.fields.mobilephone" v-model="form.mobilephone" autocomplete="off">
                                <error-ele v-if="formValidationErrors && formValidationErrors['mobilephone']"
                                           :errors="formValidationErrors['mobilephone']" />
                            </div>




                            <div class="text-center text-primary d-block mb-2 font-weight-bold" data-toggle="collapse" data-target="#collapseExample2" >
                                <span class="cursor-pointer"> {{ initialData.lang.profile.fields.add_number }} <span class="icon-addplus"></span></span>
                            </div>

                            <div class="collapse mb-3" id="collapseExample2">
                                <div class="form-group">
                                    <input type="text" @keypress="isNumberKey" required class="form-control" :placeholder="initialData.lang.profile.fields.homephone" v-model="form.homephone" autocomplete="off">
                                    <error-ele v-if="formValidationErrors && formValidationErrors['homephone']"
                                           :errors="formValidationErrors['homephone']" />
                                </div>
                            </div>





                            <div class="text-center text-primary d-block mb-2 font-weight-bold" data-toggle="collapse" data-target="#collapseExample" >
                                <span class="cursor-pointer"> {{ initialData.lang.app_add_authorize_user }} <span class="icon-addplus"></span></span>
                            </div>

                            <div class="collapse mb-3" id="collapseExample">
                                <div class="form-group">
                                    <input type="text" class="form-control" autocomplete="off" :placeholder="initialData.lang.profile.fields.auth_user_fname" v-model="form.auth_user_fname" autofill="off">
                                    <error-ele v-if="formValidationErrors && formValidationErrors['auth_user_fname']"
                                           :errors="formValidationErrors['auth_user_fname']" />
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <input type="text" class="form-control" autocomplete="off" :placeholder="initialData.lang.profile.fields.auth_user_lname" v-model="form.auth_user_lname" autofill="off">
                                        <error-ele v-if="formValidationErrors && formValidationErrors['auth_user_lname']"
                                           :errors="formValidationErrors['auth_user_lname']" />
                                    </div>
                                    <div class="form-group col-md-6">
                                        <input type="number" class="form-control" autocomplete="off" :placeholder="initialData.lang.profile.fields.auth_user_phone" v-model="form.auth_user_phone" autofill="off">
                                        <error-ele v-if="formValidationErrors && formValidationErrors['auth_user_phone']"
                                           :errors="formValidationErrors['auth_user_phone']" />
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="text" class="form-control" autocomplete="off" :placeholder="initialData.lang.profile.fields.auth_user_email" v-model="form.auth_user_email" autofill="off">
                                    <error-ele v-if="formValidationErrors && formValidationErrors['auth_user_email']"
                                           :errors="formValidationErrors['auth_user_email']" />
                                </div>

                            </div>

                        </form>
                    </div>
                </main>
            </div>
        </div>

        <footer class="d-flex flex-column justify-content-center">
            <div class="d-flex justify-content-between align-items-center px-2 px-md-4 px-lg-5 footer-nav">
                <div></div>
                <div>
                    <button-loader
                            :disabled="submitLoading"
                            :classes="'btn btn-primary'"
                            @click="saveForm(initialData.lang.app_addPetNow)"
                    >
                        {{ initialData.lang.app_registerUser }}
                        <span class="icon-arrow-right"></span>
                    </button-loader>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        components : {
            'error-ele' : require('../../components/form/Error').default,
            'v-select' : require('../../components/form/Select').default,
            'button-loader' : require('../../components/form/ButtonLoader').default,
            'v-datepicker' : require('../../components/form/DatePicker').default,
            'v-google-autocomplete' : require('../../components/form/GoogleAutoComplete').default,
        },
        props : ['initialData'],

        data() {
            return {
                options: [{ label : 'Select Value' , value : '' },{ label : 'One' , value : '1' }],
                submitLoading: false,
                form : this.getDefaultForm(),
            }
        },

        methods: {
            saveForm(button) {
                let param = _.clone(this.form);
                param.verification_token = 'gg';
                this.submitLoading = true;
                param.submitbutton = button;

                if(this.form.dob && this.form.dob.toString().trim() !== '') {
                    param.dob = this.form.dob;
                }

                let url = this.apiRequestUrl + '/' + this.initialData.current_lang + '/createProfile';
                axios.post(url, param)
                    .then((response) => {
                        this.setCurrentForm('register-form-step-1', param);
                        window.location.href = response.data;
                        this.submitLoading = false;
                    })
                    .catch((error) => {
                        this.submitLoading = false;
                        if (error.response.status === 422) {
                            this.formError = error.response.data;
                        }
                    });
            },

            back() {
                window.history.go(-1);
            },

            getDefaultForm() {
                return {
                    name: '',
                    firstname: '',
                    lastname : '',
                    dob: '',
                    auth_user_fname: '',
                    auth_user_lname: '',
                    auth_user_phone: '',
                    auth_user_email: '',
                    city: this.initialData.city,
                    province : this.initialData.province,
                    postalcode: '',
                    address: '',
                    mobilephone: '',
                    homephone: '',
                }
            },

            isNumberKey(event) {
                let charCode = (event.which) ? event.which : event.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)){
                    event.preventDefault();
                    return false;
                }
                return true
            }
        },

        mounted() {
            if(Object.keys(this.getCurrentForm('register-form-step-1')).length > 0) {
                this.form = this.getCurrentForm('register-form-step-1')
            }
        }
    }
</script>
